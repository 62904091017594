import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="footer__wrapper">
          <div className="footer__nav">
            <Link to="/" className="footer__brand">
              <img src={require("../assets/images/logo.svg")} alt="" />
            </Link>
            <Link
              to="/terms-and-conditions/"
              className="btn-custom btn-custom--small btn-custom--blue"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/cookies-policy/"
              className="btn-custom btn-custom--small btn-custom--blue"
            >
              Cookies Policy
            </Link>
          </div>
          <div className="footer__actions">
            <a href="/">
              <img
                src={require("../assets/images/logo-google-play.svg")}
                alt=""
              />
            </a>
            <a href="/">
              <img
                src={require("../assets/images/logo-app-store.svg")}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="footer__copyrights">
          <p>Copyright &copy; 2020 Drivelee</p>
          <a href="https://adream.pl/en/" target="_blank" rel="noreferrer">
            Proudly designed by
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
