import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

const FormRegisterCompany = () => {
  const [loading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [companyAddress, setCompanyAddress] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [companyPhone, setCompanyPhone] = useState("")

  const submitForm = e => {
    if (e) e.preventDefault()
    setLoading(true)

    let formData = new FormData()
    formData.set("firstname", companyName)
    formData.set("address", companyAddress)
    formData.set("email", companyEmail)
    formData.set("phone", companyPhone)

    axios({
      method: "post",
      url:
        "https://drivelee.zenx.pl/wp-json/contact-form-7/v1/contact-forms/6/feedback",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        console.log("Data api success...")
        setLoading(false)
        navigate("/thank-you/")
      })
      .catch(() => {
        console.log("Data api error...")
      })
  }

  return (
    <>
      <h3>Register as a company</h3>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="col-sm-6">
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              placeholder="Name*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="text"
              id="companyAddress"
              value={companyAddress}
              onChange={e => setCompanyAddress(e.target.value)}
              placeholder="Address*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="email"
              id="companyEmail"
              value={companyEmail}
              onChange={e => setCompanyEmail(e.target.value)}
              placeholder="E-mail*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="tel"
              id="companyPhone"
              value={companyPhone}
              onChange={e => setCompanyPhone(e.target.value)}
              placeholder="Phone number*"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn-custom btn-custom--green btn-custom--block"
        >
          {loading ? "Sending..." : "Register"}
        </button>
      </form>
    </>
  )
}

export default FormRegisterCompany
