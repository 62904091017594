import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

const FormRegisterDealer = () => {
  const [loading, setLoading] = useState(false)
  const [dealerName, setDealerName] = useState("")
  const [dealerAddress, setDealerAddress] = useState("")
  const [dealerEmail, setDealerEmail] = useState("")
  const [dealerPhone, setDealerPhone] = useState("")

  const submitForm = e => {
    if (e) e.preventDefault()
    setLoading(true)

    let formData = new FormData()
    formData.set("firstname", dealerName)
    formData.set("address", dealerAddress)
    formData.set("email", dealerEmail)
    formData.set("phone", dealerPhone)

    axios({
      method: "post",
      url:
        "https://drivelee.zenx.pl/wp-json/contact-form-7/v1/contact-forms/7/feedback",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        console.log("Data api success...")
        setLoading(false)
        navigate("/thank-you/")
      })
      .catch(() => {
        console.log("Data api error...")
      })
  }

  return (
    <>
      <h3>Register as a cal dealer</h3>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="col-sm-6">
            <input
              type="text"
              id="dealerName"
              value={dealerName}
              onChange={e => setDealerName(e.target.value)}
              placeholder="Name*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="text"
              id="dealerAddress"
              value={dealerAddress}
              onChange={e => setDealerAddress(e.target.value)}
              placeholder="Address*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="email"
              id="dealerEmail"
              value={dealerEmail}
              onChange={e => setDealerEmail(e.target.value)}
              placeholder="E-mail*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="tel"
              id="dealerPhone"
              value={dealerPhone}
              onChange={e => setDealerPhone(e.target.value)}
              placeholder="Phone number*"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn-custom btn-custom--green btn-custom--block"
        >
          {loading ? "Sending..." : "Register"}
        </button>
      </form>
    </>
  )
}

export default FormRegisterDealer
