import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

const FormRegisterCustomer = () => {
  const [loading, setLoading] = useState(false)
  const [customerName, setCustomerName] = useState("")
  const [customerAddress, setCustomerAddress] = useState("")
  const [customerEmail, setCustomerEmail] = useState("")
  const [customerPhone, setCustomerPhone] = useState("")

  const submitForm = e => {
    if (e) e.preventDefault()
    setLoading(true)

    let formData = new FormData()
    formData.set("firstname", customerName)
    formData.set("address", customerAddress)
    formData.set("email", customerEmail)
    formData.set("phone", customerPhone)

    axios({
      method: "post",
      url:
        "https://drivelee.zenx.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        console.log("Data api success...")
        setLoading(false)
        navigate("/thank-you/")
      })
      .catch(() => {
        console.log("Data api error...")
      })
  }

  return (
    <>
      <h3>Register as a customer</h3>
      <form onSubmit={submitForm}>
        <div className="row">
          <div className="col-sm-6">
            <input
              type="text"
              id="customerName"
              value={customerName}
              onChange={e => setCustomerName(e.target.value)}
              placeholder="Name*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="text"
              id="customerAddress"
              value={customerAddress}
              onChange={e => setCustomerAddress(e.target.value)}
              placeholder="Address*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="email"
              id="customerEmail"
              value={customerEmail}
              onChange={e => setCustomerEmail(e.target.value)}
              placeholder="E-mail*"
              required
            />
          </div>
          <div className="col-sm-6">
            <input
              type="tel"
              id="customerPhone"
              value={customerPhone}
              onChange={e => setCustomerPhone(e.target.value)}
              placeholder="Phone number*"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn-custom btn-custom--green btn-custom--block"
        >
          {loading ? "Sending..." : "Register"}
        </button>
      </form>
    </>
  )
}

export default FormRegisterCustomer
